import { render, staticRenderFns } from "./TheWindowMain.vue?vue&type=template&id=75ba3534&scoped=true"
import script from "./TheWindowMain.vue?vue&type=script&lang=js"
export * from "./TheWindowMain.vue?vue&type=script&lang=js"
import style0 from "./TheWindowMain.vue?vue&type=style&index=0&id=75ba3534&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75ba3534",
  null
  
)

export default component.exports